<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：规格设置</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="9">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="$refs.AddSpecification.addEvent()">添加规格
                                </el-button>
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="removeBatchEvent">批量删除
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round size="mini"
                                           @click="getList">搜索查询
                                </el-button>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " import custom print
                                             ref="xToolbar2">
                                </vxe-toolbar>
                                <el-button style=" margin-top: 8px; margin-left: 8px; " type="primary" round size="mini"
                                           @click="exportDataEvent">导出
                                </el-button>
                                <!--<vxe-button type="text" title="模板下载"
                                            class="vxe-button type&#45;&#45;button el-icon-notebook-2 is&#45;&#45;circle"
                                            @click="downFileEvent" style=" margin-right: 12px; "></vxe-button>-->
                            </el-col>

                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid ref="xGrid"
                                  :checkbox-config="{range:true}"
                                  :import-config="{
                                        remote: true,
                                        importMethod: this.importMethod,
                                        modes: ['insert', 'covering'],
                                        type: ['xlsx'],
                                        types: ['xlsx']
                                   }"
                                  border
                                  resizable
                                  show-overflow
                                  keep-source
                                  height="650"
                                  highlight-current-row
                                  :print-config="{}"
                                  :data="tableDataMain"
                                  :columns="tableColumn"
                                  :mouse-config="{selected: false}">
                            <template #operate="{row}">
                                <template v-if="$refs.xGrid.isActiveByRow(row)">
                                    <vxe-button icon="el-icon-document-checked" status="primary" title="保存" circle
                                                @click="saveRowEvent(row)"></vxe-button>
                                </template>
                                <template v-else>
                                    <vxe-button icon="el-icon-edit-outline" title="编辑" circle
                                                @click="editRowEvent(row)"></vxe-button>
                                </template>
                                <vxe-button icon="el-icon-delete" title="删除" circle
                                            @click="removeRowEvent(row)"></vxe-button>
                            </template>
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :page-sizes.sync="tablePage.pageSizes"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
        <el-dialog width="1220px" :title="!isShowDialog ? '失败' : '导入数据'" :visible.sync="dialogFormVisible"
                   :close-on-click-modal="false">
            <vxe-grid
                    border
                    resizable
                    height="400"
                    ref="xGridImport"
                    :columns="tableImport"
                    :data="tableDataImport"
                    :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil-square-o'}">
            </vxe-grid>
            <div slot="footer" class="dialog-footer">
                <template v-if="isShowDialog">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                </template>
                <template v-else>
                    <el-button @click="dialogFormVisible = false">导入失败</el-button>
                </template>
                <el-button type="primary" @click="saveImport" v-show="isShowDialog">确 定</el-button>
            </div>
        </el-dialog>

        <AddSpecification ref="AddSpecification" :isCustomer="true"></AddSpecification>
    </div>
</template>
<script>
    import {filterRender} from "@/utils/gird-filter";

    import AddSpecification from "../../components/AddSpecification";

    export default {
        components: {AddSpecification},
        data() {
            return {
                uuid: '',//导入的uuid
                isShowDialog: '',
                tableColumn: [
                    {type: 'checkbox', visible: true, width: 60},
                    {
                        field:'id', width: 100, title: '规格编号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/specification/searchHeard')
                       }
                   },
                    {
                        field:'category_name', width: 120, title: '类别名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/specification/searchHeard')
                       }
                   },
                    {
                        field:'specification_name', width: 120, title: '规格名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/specification/searchHeard')
                       }
                   },
                    {
                        field:'a_side', width: 120, title: 'A边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/specification/searchHeard')
                       }
                   },
                    {
                        field:'b_side', width: 120, title: 'B边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/specification/searchHeard')
                       }
                   },
                    {
                        field:'thickness', width: 120, title: '厚度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/specification/searchHeard')
                       }
                   },
                    {
                        field:'length_extent', width: 120, title: '长度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/specification/searchHeard')
                       }
                   },
                    {
                        field:'theoretical_weight',
                        width: 90, className: 'unEditor',
                        titleHelp: {message: '理论重量 = 理论支重 * 支数\n(吨)'},
                        title: '理论重量',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/specification/searchHeard')
                       }
                   },
                    {
                        field:'theoretical_weight_single',
                        width: 200,
                        title: '理论支重(kg)',
                        titleHelp: {message: '理论支重=[(边长a+边长b)*2-4*壁厚]*壁厚*0.00785*长度\n(kg)'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/specification/searchHeard')
                       }
                   },
                    {field:'operate',title: '操作', width: 160, slots: {default: 'operate'}},
                ],
                tableImport: [
                    {field:'id', width: 80, title: '规格编号'},
                    {field:'category_name', width: 80, title: '类别名称'},
                    {
                        field:'specification_name', width: 150, title: '规格名称'
                   },
                    {field:'a_side', width: 80, title: 'A边'},
                    {field:'b_side', width: 80, title: 'B边'},
                    {field:'thickness', width: 80, title: '厚度'},
                    {field:'length_extent', width: 80, title: '长度'},
                    {
                        field:'theoretical_weight_single',
                        width: 120, className: 'unEditor',
                        titleHelp: {message: '理论支重=[(边长a+边长b)*2-4*壁厚]*壁厚*0.00785*长度\n(kg)'},
                        title: '理论支重'
                   },
                    {
                        field:'error', title: '系统提示', width: 150, slots: {
                            default: ({row}) => {
                                return [
                                    <el-link type="danger">{row.error}</el-link>
                                ]
                           },
                       }
                   }
                ],
                tableDataImport: [],
                tableDataMain:[],
                dialogFormVisible: false,
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [200,500, 1000,2000]
               },
                filterData: {},
                sortData: {},

                activeName: 'first',
                remvoeIds: [],
                banIds: [],
                changeId: '',
                account: [],
           }
       },
        methods: {
            exportDataEvent() {
                this.$refs.xGrid.exportData({
                    type: 'xlsx',
                    columnFilterMethod ({column}) {
                        return ['id', 'category_name', 'specification_name'].includes(column.property)
                   }
               })
           },
            editRowEvent(row) {
                this.$refs.AddSpecification.showEdit = true;
                this.$refs.AddSpecification.isAdd = false;
                this.$axios({
                    method: 'post',
                    url: '/admin/specification/editVue',
                    params: {
                        id: row.id
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.$refs.AddSpecification.specificationForm.id = response.data.id;
                        this.$refs.AddSpecification.specificationForm.category_name = response.data.category_name;
                        this.$refs.AddSpecification.specificationForm.specification_name = response.data.specification_name;
                        this.$refs.AddSpecification.specificationForm.length_extent = response.data.length_extent;
                        this.$refs.AddSpecification.specificationForm.theoretical_weight = response.data.theoretical_weight;
                   }

               }).catch((error) => {
                    console.log(error)
               });
           },




            removeBatchEvent() {
                const $grid = this.$refs.xGrid
                const selectRecords = $grid.getCheckboxRecords();
                this.remvoeIds = [];
                for (let i = 0; i < selectRecords.length; i++) {
                    this.remvoeIds.push(selectRecords[i].id)
               }
                if (selectRecords.length > 0) {
                    this.$XModal.confirm('您确定要删除 ' + this.remvoeIds.length + ' 条数据?').then(type => {
                        if (type === 'confirm') {
                            //传送删除动作
                            this.$axios({
                                method: 'post',
                                url: '/admin/specification/deleteBatchVue',
                                params: {
                                    remvoeIds: this.remvoeIds
                               }
                           }).then((response) => {         //这里使用了ES6的语法
                                //(response)       //请求成功返回的数据
                                if (response.status === 200 && response.data.state == 'ok') {
                                    this.$message({
                                        showClose: true,
                                        message: '批量删除成功',
                                        type: 'success'
                                   });
                                    this.getList();
                               } else {
                                    this.$message({
                                        showClose: true,
                                        message: response.data.msg,
                                        type: 'error'
                                   });
                                    return
                               }
                           }).catch((error) => {
                                console.log(error)
                           });
                       }
                   });
               } else {
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                   });
               }

           },
            removeRowEvent(row) {
                this.$XModal.confirm('您确定要删除该数据?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/specification/deleteVue',
                            params: {
                                id: row.id
                           }
                       }).then((response) => {         //这里使用了ES6的语法
                            //(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.getList();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });

                   }
               })
           },
            getList() {
                this.$axios({
                    method: 'POST',
                    url: '/admin/specification',
                    params: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            saveImport() {
                const loading = this.$loading({
                    lock: true,
                    text: '数据录入中，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'post',
                    url: '/admin/specification/saveImportExcel',
                    params: {
                        uuid: this.uuid
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '导入成功',
                            type: 'success'
                       });
                        this.dialogFormVisible = false
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
                    this.getList();
               }).catch((error) => {
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
                    console.log(error)
               });
           },
            downFileEvent() {
                window.open('/template/specification.xlsx', '_blank')
           },
            importMethod({file}) {
                const loading = this.$loading({
                    lock: true,
                    text: '导入中，请喝杯水，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                const formBody = new FormData()
                formBody.append('file', file)
                this.$axios({
                    method: 'post',
                    url: '/admin/specification/importExcel',
                    data: formBody,
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.tableDataImport = response.data.list
                        this.isShowDialog = response.data.isOkSave
                        this.uuid = response.data.uuid
                        this.dialogFormVisible = true;
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList()
           },

            saveRowEvent() {
                const $grid = this.$refs.xGrid
                $grid.clearActived().then(() => {
                    this.gridOptions.loading = true
                    setTimeout(() => {
                        this.gridOptions.loading = false
                        this.$XModal.message({content: '保存成功！', status: 'success'})
                   }, 300)
               })
           },
       },
        created() {
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                this.getList()
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    /deep/ .el-form-item__error {
        position: initial !important;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/ .vxe-input--panel {
        position: fixed !important;
        left: auto;
   }

    /deep/ .el-input-number .el-input__inner {
        text-align: initial !important;
   }

    .el-form-item__content .el-input-group {
        vertical-align: initial;
   }
</style>
